import React, { useEffect, useState } from 'react';
import './styles/App.css';
import './styles/responsive.css';
import './styles/style.css';
import { PageLayout } from './components/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
// import { ProfileData } from './components/ProfileData';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullScreenButton from './fullScreenFunction/fullScreenFunction';


const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedReportUrl, setSelectedReportUrl] = useState('');
    const [loading, setLoading] = useState(true);

    const RequestProfileData = () => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                return callMsGraph(response.accessToken);
            })
            .then((data) => setGraphData(data))
            .catch((error) => {
                console.error('Error acquiring token or calling MS Graph:', error);
            });
    };

    // This function simulates the loading process after login
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Stop loading after 3 seconds
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);


    const handleClose = () => setShowModal(false);
    const handleShow = (url) => {
        setSelectedReportUrl(url);
        setShowModal(true);
    };

    // Function to toggle full screen for the report iframe
    const handleFullScreen = () => {
        const iframe = document.querySelector('.modal-body iframe');
        if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
            // Firefox
            iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
            // Chrome, Safari, and Opera
            iframe.webkitRequestFullscreen();
        } else if (iframe.msRequestFullscreen) {
            // IE/Edge
            iframe.msRequestFullscreen();
        }
    };
        return (
            <div className="profile-content">
                {loading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <h2>Loading...</h2>
                        <p>Please wait while we load your reports.</p>
                    </div>
                ) : (
                    <>
                        {/* Main Heading */}
                        <span className="welcome-message text-center d-block my-4">
                            Find and share actionable insights to make data-driven decisions.
                        </span>
    
                        {/* Reports Section Heading */}
                        <h5 className="report-section text-center mt-4">
                            <strong>Reports</strong>
                        </h5>
    
                        {/* Cards Section */}
                        <div className="d-flex flex-wrap justify-content-center mt-3 gap-5" id="cards-container">
                            {[
                                {
                                    imgSrc: '../kpm-plug-in-usage-data.png',
                                    reportUrl: 'https://app.powerbi.com/reportEmbed?reportId=8f5dbee1-b12c-4869-bf98-67b404c1b0e0&autoAuth=true&ctid=98cbf99b-c949-4ff7-9c4b-4f544bf17bae',
                                    title: 'Revit Plugin Usage Data',
                                    description: 'Find and share actionable insights to make',
                                    icon: 'fas fa-plug',
                                },
                                {
                                    imgSrc: '../kpm-energy-analytics.png',
                                    reportUrl: 'https://app.powerbi.com/reportEmbed?reportId=e95cfc43-ea90-45d5-ad88-8befdb8fd150&autoAuth=true&ctid=98cbf99b-c949-4ff7-9c4b-4f544bf17bae',
                                    title: 'Energy Analytics',
                                    description: 'Find and share actionable insights to make',
                                    icon: 'fas fa-bolt',
                                },
                                {
                                    imgSrc: '../kpm-employee-data.png',
                                    reportUrl: 'https://lookerstudio.google.com/embed/u/1/reporting/3f286b33-3727-4f47-b996-61b625f93ba9/page/Nd4sD',
                                    title: 'Employee Data',
                                    description: 'Find and share actionable insights to make',
                                    icon: 'fas fa-users',
                                },
                            ].map((report, index) => (
                                <Card
                                    key={index}
                                    style={{
                                        width: '350px',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '15px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s ease-in-out',
                                    }}
                                    onDoubleClick={() => handleShow(report.reportUrl)}
                                >
                                    <div
                                        style={{
                                            background: 'linear-gradient(180deg, #009ffd, #2a2a72)',
                                            padding: '20px',
                                        }}
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={report.imgSrc}
                                            style={{
                                                height: '180px',
                                                objectFit: 'cover',
                                                borderRadius: '10px',
                                                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                                            }}
                                        />
                                    </div>
                                    <Card.Body className="text-center">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <i className={`${report.icon} fa-2x mb-2 me-2`} style={{ color: '#ff9800' }}></i>
                                            <Card.Title style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
                                                {report.title}
                                            </Card.Title>
                                        </div>
                                        <Card.Text style={{ fontSize: '0.9rem', color: '#666', textAlign: 'left' }}>
                                            {report.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
    
                        {/* Modal for showing reports */}
                        <Modal show={showModal} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title style={{ fontSize: '1.2rem' }}>Report</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <iframe src={selectedReportUrl} style={{ width: '100%', height: '500px', border: 'none' }} title="Report"></iframe>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* Integrate the FullScreenButton component */}
                                <FullScreenButton onClick={handleFullScreen} />
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </div>
        );
    };

const MainContent = () => {
    const { accounts } = useMsal();

    // Google Analytics setup
    useEffect(() => {
        // Load the Google Analytics script dynamically
        const loadGA = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=G-CXN9X2439V`;
            document.body.appendChild(script);

            const inlineScript = document.createElement('script');
            inlineScript.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-CXN9X2439V');
            `;
            document.body.appendChild(inlineScript);
        };

        // Initialize Google Analytics
        loadGA();

        // Optionally, you can send events to Google Analytics after login
        if (accounts.length > 0) {
            window.gtag('event', 'login', {
                method: 'MSAL'
            });
        }
    }, [accounts]);

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                {/* Image container */}
                <div className="image-container">
                    <img src="../App_2.0_imgbg-new1.png" alt="KPM Login Display" className="full-width-image" />
                </div>

            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
