import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

/**
 * Renders a sign-out button using a popup
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const accounts = useMsal().accounts;

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/" // Redirects to home page after logout
        }).then(() => {
            // Reload the page after successful logout
            window.location.reload();
        }).catch(e => {
            console.log(e);
            // Optionally, you can still reload the page here on error if desired
            window.location.reload();
        });
    };

    return (
        <div className="d-flex align-items-center" id="btn-welcome"> {/* Flex container */}
            {/* Display the user's name */}
            {accounts.length > 0 && (
                <h5 className="user-name" style={{ margin: '0 10px' }}>
                    Welcome, {accounts[0]?.name?.toUpperCase()}
                </h5>
            )}
            
            <Button id="logout-btn"
                variant="secondary"
                onClick={handleLogout}
                style={{
                    backgroundColor: '#f80f07', // Red color
                    color: 'white', // Text color
                    border: 'none', // No border
                    padding: '10px 20px', // Padding
                    borderRadius: '5px', // Rounded corners
                    cursor: 'pointer', // Pointer cursor
                    transition: 'background-color 0.3s ease', // Smooth transition
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#d90429')} // Darker red on hover
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f80f07')} // Reset to original color
            >
                Sign out
            </Button>
        </div>

    );
};
