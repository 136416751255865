// FullScreenButton.js
import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

const FullScreenButton = ({ onClick }) => {
    return (
        <Button variant="light" onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faExpand} style={{ marginRight: '5px' }} />
            Full Screen
        </Button>
    );
};

export default FullScreenButton;
