import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

const allowedEmails = [
    "Ritesh.c@kpm-engineering.com",
    "mamta.j@kpm-engineering.com",
    "rahina.qazi@kpm-engineering.com",
    "Zoya.k@kpm-engineering.com",
    "sonali.rane@kpm-engineering.com",
    "zubair.a@kpm-engineering.com"
];

/**
 * Renders a button for logging in using a redirect
 */
export const SignInButton = () => {
    const { instance, accounts } = useMsal();

    const handleLogin = async () => {
        try {
            await instance.loginRedirect(loginRequest);
        } catch (e) {
            console.log(e);
        }
    };

    // Check the account after redirection
    useEffect(() => {
        if (accounts.length > 0) {
            const email = accounts[0].username; // Get the user's email
            if (!allowedEmails.includes(email)) {
                console.log("Unauthorized login attempt by:", email);
                instance.logout(); // Log out the user if unauthorized
                alert("You are not authorized to access this application.");
            } else {
                console.log("Login successful for:", email);
                // Proceed with your app logic here
            }
        }
    }, [accounts, instance]);

    return (
        <Button id="login-btn"
            variant="secondary"
            onClick={handleLogin}
            style={{
                backgroundColor: '#f80f07', // Red color
                color: 'white', // Text color
                border: 'none', // No border
                padding: '10px 20px', // Padding
                borderRadius: '5px', // Rounded corners
                cursor: 'pointer', // Pointer cursor
                transition: 'background-color 0.3s ease', // Smooth transition
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#d90429'} // Darker red on hover
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#f80f07'} // Reset to original color
        >
            Sign in
        </Button>
    );
};
